module.exports = {
  curbsideApi: `https://vu0c9jlzvd.execute-api.us-east-1.amazonaws.com/nonprod/signet/sit-b`,
  totpInterval: 1200,
  sentry: 'https://a4a51f97d4dc4ac28bb064f1f6a7f015@o37102.ingest.sentry.io/5579302',
  modalDefaults: {
    showOverlay: false,
    showTapGuard: false,
    showOrderModal: false,
    showScheduleModal: false,
    showVehicleDetailsModal: false,
    showMissingDetails: false,
    showComeBackLater: false
  }
};
